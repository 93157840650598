import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentApiService } from '../../api/config/environment-api.service';
import { Configuration } from '../../types/configuration/Configuration';
import { WINDOW } from '../../providers/window.provider';
import { ApiUrlEntity } from './entities/api-url.entity';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentRepositoryService {
  public apiUrl!: ApiUrlEntity;

  public constructor(
    private envApi: EnvironmentApiService,
    @Inject(WINDOW) private window: Window,
  ) {}

  public getConfig(): Observable<Configuration> {
    return this.envApi.getConfig();
  }

  public getApiUrl(): Observable<ApiUrlEntity> {
    return this.getConfig().pipe(
      map((config) => {
        const baseUrl = this.getBaseUrl(config);

        return {
          baseUrl,
          authUrl: `${baseUrl}/v1/auth/`,
          apiUrl: `${baseUrl}/v1/api/`,
          apiUrlV2: `${baseUrl}/v2/api/`,
          apiUtilUrl: `${baseUrl}/v1/util/`,
          cardConfigUrl: config.carDatabaseUrl,
          captchaKey: config.captchaApiKey,
          mixpanelApiKey: config.mixpanelApiKey,
          assetsUrl: `https://${config.assetsUrl}`,
        };
      }),
    );
  }

  private getBaseUrl(config: Configuration): string {
    const apiConfiguration = config.api;
    const protocol = apiConfiguration?.protocol || this.window.location.protocol.slice(0, -1);
    const host = apiConfiguration?.host || this.window.location.hostname;
    const port = apiConfiguration?.port;

    const baseUrl = `${protocol}://${host}`;
    if (port) {
      return `${baseUrl}:${port}`;
    }

    return baseUrl;
  }
}
