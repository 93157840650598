import { APP_INITIALIZER, ErrorHandler, Provider } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

export class SentryUtils {
  public static isSentryEnabled = true;

  public static init(): void {
    if (!SentryUtils.isSentryEnabled) {
      return;
    }

    const envs = {
      'dev-vev-iq.com': 'dev',
      'qa-vev-iq.com': 'qa',
      'vev-iq.com': 'prod',
    } as const;

    Sentry.init({
      dsn: 'https://b65cee226fc73b531809af31340e8f54@o4506631232094208.ingest.sentry.io/4506660194811904',
      tracePropagationTargets: ['localhost', /^https:\/\/(dev-|qa-)?vev-iq.com\/v1/],
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 0.1,
      // Decide environment based on second-level domain
      environment: envs[window.location.hostname.split('.').slice(-2).join('.')] ?? 'local',
    });
  }

  public static getSentryProviders(): Provider[] {
    if (!SentryUtils.isSentryEnabled) {
      return [];
    }

    return [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
    ];
  }
}
