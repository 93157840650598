import { Inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ApiUrlEntity } from '../repository/config/entities/api-url.entity';
import { EnvironmentRepositoryService } from '../repository/config/environment.repository.service';
import { Env, ENV } from '../providers/env.provider';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  public constructor(
    private configRepository: EnvironmentRepositoryService,
    @Inject(ENV) private env: Env,
  ) {}

  public init(): Observable<ApiUrlEntity> {
    return this.configRepository.getApiUrl().pipe(
      tap((apiUrl) => {
        this.env.set(apiUrl);
      }),
    );
  }
}
