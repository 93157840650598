import { forkJoin, Observable, of, tap } from 'rxjs';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { CentralServerService } from '../services/central-server.service';
import { EnvironmentService } from '../services/environment.service';
import { MixpanelService } from '../services/mixpanel.service';

const appFactory =
  (
    centralServerService: CentralServerService,
    apiConfigService: EnvironmentService,
    mixpanelService: MixpanelService,
  ): (() => Observable<any>) =>
  () =>
    forkJoin([
      apiConfigService.init().pipe(
        tap(() => {
          mixpanelService.init();
        }),
      ),
      of(centralServerService.initUserToken()),
    ]);

export const appInitProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: appFactory,
  deps: [CentralServerService, EnvironmentService, MixpanelService],
  multi: true,
};
