import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RESTServerRoute } from 'types/Server';
import { ENV, Env } from '../../providers/env.provider';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  private static readonly urlsToExcludeWithStart = ['http', 'assets/', '/assets/'];
  private static readonly urlsToExcludeWithEnd = ['config.json'];

  private static readonly shouldUseAuthenticatedBaseApi = [
    'billing/scan-pay',
    RESTServerRoute.REST_SIGNIN,
    RESTServerRoute.REST_SIGNON,
    RESTServerRoute.REST_PASSWORD_RESET,
    RESTServerRoute.REST_MAIL_CHECK,
  ];
  private static readonly shouldUseV2Apis = [RESTServerRoute.REST_LOGS, RESTServerRoute.REST_LOG];
  private static readonly shouldUseUtilApis = [RESTServerRoute.REST_TENANT_LOGO];

  public constructor(@Inject(ENV) private env: Env) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludeStartWith = BaseUrlInterceptor.urlsToExcludeWithStart.some((exclude) => req.url.startsWith(exclude));
    const excludeEndWith = BaseUrlInterceptor.urlsToExcludeWithEnd.some((exclude) => req.url.endsWith(exclude));
    if (excludeStartWith || excludeEndWith) {
      return next.handle(req);
    }

    const requestUrl = req.url.startsWith('/') ? req.url.substring(1) : req.url;
    const clonedRequest = req.clone({
      url: this.getBaseUrl(req.url) + requestUrl,
    });

    return next.handle(clonedRequest);
  }

  private getBaseUrl(url: string): string {
    if (BaseUrlInterceptor.shouldUseAuthenticatedBaseApi.some((useAuth) => url.includes(useAuth))) {
      return this.env().authUrl;
    }

    if (BaseUrlInterceptor.shouldUseV2Apis.some((useV2) => url.includes(useV2))) {
      return this.env().apiUrlV2;
    }

    if (BaseUrlInterceptor.shouldUseUtilApis.some((useUtil) => url.includes(useUtil))) {
      return this.env().apiUtilUrl;
    }

    return this.env().apiUrl;
  }
}
