import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from '../../types/configuration/Configuration';

@Injectable()
export class EnvironmentApiService {
  public constructor(private httpClient: HttpClient) {}

  public getConfig(): Observable<Configuration> {
    return this.httpClient.get<Configuration>('/assets/config.json');
  }
}
